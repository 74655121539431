import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = (data) => (
  <StaticQuery
    query={
      graphql`
  query ltemsQuery {
    allItemsJson {
      nodes {
        name
        id
        price
        type
        description
        currency
        img{
          childImageSharp {
            fluid {
              src
              srcSet
            }
          }
        }
      }
    }
  } 
`}
    render={data => (
      <Layout index>
        <SEO title="Home" />
        <div className="container index-page">
          <div className="columns is-variable is-2" style={{ height: '100%' }}>
            {data.allItemsJson.nodes.map(item =>
              <div key={item.id}
                className="column is-one-fourth ">
                <Link style={{ height: '100%' }} to={`/product/${item.id}`}>
                  <div className="item-card">
                    <div className="text-container">
                      <h2 className="title is-size-4-widescreen is-size-7-desktop has-text-weight-normal">{item.type}</h2>
                      <h4 className="item-type ">{item.name}</h4>
                    </div>
                    <div className="img-wrapper"><img src={item.img.childImageSharp.fluid.src} srcSet={item.img.childImageSharp.fluid.srcSet} alt={item.name} /></div>
                    <div className="item-footer">
                      <p className="item-price is-size-6-desktop is-size-4-widescreen">{item.price} <sup>{item.currency}</sup></p>
                      <Link to={`/product/${item.id}`} className="button is-size-5-widescreen is-size-6-desktop">Sign Up</Link>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Layout>
    )}
  />
)

export default IndexPage


